export const staffTableHeader = [
  // 表头数据
  { prop: 'stff_name', label: '姓名' },
  { prop: 'cptt_name', label: '公司' },
  { prop: 'dept_name', label: '部门' },
  { prop: 'dept_team_name', label: '小组' }
];
export const procTableHeader = [
  // 表头数据
  { prop: 'stff_name', label: '姓名' },
  { prop: 'user_name', label: '账号' },
  { prop: 'dept_name', label: '部门' },
  { prop: 'dept_team_name', label: '小组' }
];
