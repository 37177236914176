<template>
  <div class="wrapper">
    <el-input v-model="inputVal" :disabled="disabledFn" placeholder="请选择对应员工">
      <template slot="append">
        <el-link type="primary" @click="openDialog()" :disabled="disabled">选择</el-link>
      </template>
    </el-input>
    <el-dialog :title="title" :visible.sync="dialogTableVisible">
      <template>
        <div class="vg_mb_8">
          <el-button type="info" @click="refreshClick">刷新</el-button>
        </div>
        <el-button type="danger" @click="cancelSelection" v-if="isShowCol">取消选择</el-button>
        <el-button type="primary" @click="sureSelection" v-if="isShowCol">确认选择</el-button>
        <div>
          <span class="vd_tips" v-if="isShowCol === false">提示：(双击表单对应数据即可选中人员)</span>
          <el-input
            v-model="searchContent"
            placeholder="请输入匹配关键字"
            size="medium"
            style="width: 200px; float: right; margin-bottom: 10px"
            @focus="focusInput"
            @blur="blurInput"
            :class="{ aa: active, bb: !active }"
          >
          </el-input>
        </div>
      </template>
      <el-table
        ref="multipleTable"
        :data="filterData"
        border
        size="mini"
        max-height="600"
        fit
        highlight-current-row
        @row-dblclick="rowDblclick"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        v-if="flag"
        class="vg_cursor"
        row-key="user_id"
      >
        <!--        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onChange}"-->
        <!--        :reserve-selection="newSure"-->
        <el-table-column :reserve-selection="true" type="selection" width="55" align="center" v-if="isShowCol === true"></el-table-column>
        <el-table-column type="index" align="center" width="55" fixed></el-table-column>
        <el-table-column
          v-for="(th, key) in tableHeader"
          :key="key"
          :prop="th.prop"
          :label="th.label"
          :fixed="th.fixed"
          :min-width="th.minWidth"
          align="center"
        >
          <!--          <template slot-scope="scope">-->
          <!--            <div v-if="th.oper">-->
          <!--              <el-button v-for="(o, key) in th.oper" :key="key" @click="o.clickFun(scope.row)" type="text" size="small">{{o.name}}</el-button>-->
          <!--            </div>-->
          <!--            <div v-else>-->
          <!--              <span v-if="!th.formatData">{{ scope.row[th.prop] }}</span>-->
          <!--              <span v-else>{{ scope.row[th.prop] | formatters(th.formatData) }}</span>-->
          <!--            </div>-->
          <!--          </template>-->
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';

export default {
  name: 'selectInput',
  data() {
    return {
      // title:'',
      dialogTableVisible: false,
      searchContent: '',
      disabledFn: true,
      newRow: {},
      multiSelection: [],
      newSure: true,
      selectedRowKeys: [],
      selectedRows: [],
      flag: true,
      active: true
    };
  },
  props: {
    title: {
      type: String,
      default: function () {
        return '';
      }
    },
    selTableData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    isShowCol: {
      type: Boolean,
      default: function () {
        return Boolean;
      }
    },
    // rowDblclick:{
    //   type: Function,
    //   default:(row,event, column) => { console.log('default: ' + row + '---' + event + '---' + column) }
    // },
    disabled: {
      type: Boolean,
      default: function () {
        return {};
      }
    },
    tableHeader: {
      type: Array,
      default: function () {
        return [];
      }
    },
    inputVal: {
      type: String,
      default: function () {
        return {};
      }
    },
    rowSelect: {
      type: Array,
      default: function () {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filterData: function () {
      let input = this.searchContent;
      let items = this.selTableData;
      let items1;
      if (input) {
        items1 = items.filter(function (item) {
          return Object.keys(item).some(function (key1) {
            return String(item[key1]).match(input);
          });
        });
      } else {
        items1 = items;
      }
      return items1;
    }
  },
  created() {},
  methods: {
    // 获取传递selTableData
    // getSelectTable(){
    //   get(stffAPI.getAllStffsV1)
    //       .then(res=>{
    //         if(res.data.code === 0){
    //           console.log('进行');
    //           this.selTableData = res.data.data
    //         }
    //       })
    // },
    refreshClick() {
      this.$emit('refreshClick');
    },
    focusInput() {
      this.active = false;
    },
    blurInput() {
      this.active = true;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    openDialog() {
      this.flag = false;
      this.dialogTableVisible = true;
      this.$nextTick(() => {
        this.flag = true;
      });
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.isShowCol === true) {
            this.rowSelect.forEach(item1 => {
              this.filterData.forEach(item => {
                let dataparams = [];
                if (item1 === item.user_id) {
                  dataparams.push(item);
                  this.toggleSelection(dataparams);
                }
              });
            });
          }
        });
      }, 500);
      this.$emit('openSelect', this.dialogTableVisible);
    },
    // 单选一个数值
    rowDblclick(row) {
      if (this.isShowCol === false) {
        this.newRow = row;
        this.$emit('getSelTableRow', this.newRow);
        this.dialogTableVisible = false;
        this.$emit('changeSelTableData');
      }
    },
    // 多选数值
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    cancelSelection() {
      this.dialogTableVisible = false;
      this.$refs.multipleTable.clearSelection();
    },
    // onChange(selectedRowKeys, selectedRows) {
    //   //选中的数据的key
    //   this.selectedRowKeys = selectedRowKeys
    //   if (selectedRows.length > 1) {
    //     //获取选中的数据的key
    //     const selectNumber = this.selectedRowKeys[1]
    //     //清空选中的key
    //     this.selectedRowKeys = []
    //     //选中的数据的key重新赋值给selectedRowKeys
    //     this.selectedRowKeys.push(selectNumber)
    //   }
    //   //获取选中的数据详细信息
    //   this.selectedRows = selectedRows[0]
    //
    // },
    sureSelection() {
      // this.$emit('getSelTableList',this.multiSelection)
      // console.log('9999',this.multiSelection)
      // this.dialogTableVisible = false
      if (this.multiSelection.length == 0) {
        return this.$message.warning('至少选择一条数据');
      }
      if (this.isShowCol === true) {
        this.$emit('getSelTableList', this.multiSelection);
        this.dialogTableVisible = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_tips {
  font-size: 12px;
  color: $color-info;
}
::v-deep .aa input {
  border-color: #ccc !important;
}
::v-deep .bb input {
  border-color: rgb(120, 143, 210) !important;
}
</style>
